@media screen and (max-width: 600px) {


  
  .container_gg .logo img{
  margin-top: 15px;
  padding-left: 10px;
  padding-right: 15px;
  width:210px;
  height:50px;
}
 .contact_icons{
  padding-left: 10px;
  padding-top: 25px;
  padding-right: 10px !important;
 
  color: black;
}
.icons-container{
  margin-right: 0px !important;
  margin-left: -40px;
}
.mobile-nav-i{
 margin-right: -10px;
  margin-top: 5px;
  margin-bottom: 50px;
}
}











.container_gg{
  display: flex;
  justify-content: space-between;
}  
.logo img{
  margin-top: 15px;
  padding-left: 80px;
  height:50px;
}
.icons-container{
  margin-right: 100px;
}
.contact_icons{
  padding-left: 10px;
  justify-content: space-around;
  padding-top: 25px;
  padding-right: 20px;
  color: black;
}
.bottom-nav-i{

}
