/* BottomNavBar.css */
@media screen and (max-width: 600px) {
  .bottom-nav-bar {
   width: 10px;
   height: 5px;
    
  }
  
  .fonts_home ul {
   margin-left: 0px;
  justify-content: space-around;
  padding: 0px;
  }
  .fonts_home ul li{
    font-size: 15px;
    padding: 10px;
    left: 15px;
  }
    
  .services_i{
   
  
  }
  }
  









  .bottom-nav-bar {
    display: flex;
    justify-content: space-between;
    background-color: white;
    overflow: hidden;
    margin-top: 10px;
  
  }
  
  .fonts_home ul {
    font-size: 20px;
    display: flex;
    color: #fff;
    text-decoration: none;
    padding: 14px 16px;
    list-style: none;
  }
  .fonts_home ul li{
    padding-left: 30px;
  }
   .fonts_home a{
    color: black;
   }