/* MobileNav.css */

.mobile-nav {
    display: none;
  }
  
  
  
  .navbar-icon {
    font-size: 30px;
    cursor: pointer;
    padding: 15px;
  }
  
  .bar {
    height: 5px;
    width: 30px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
  }
  
  .sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #fbf6f6;
    overflow-x: hidden;
    padding-top: 60px;
    transition: 0.5s;
  }
  
  .sidebar a {
    padding: 15px 15px 15px 32px;
    text-decoration: none;
    font-size: 20px;
    color: #121111;
    display: block;
    transition: 0.3s;
  }
  
  .sidebar a:hover {
    color: #383636;
  }
  
  /* Media query for small screens (max-width 768px) */
  @media only screen and (max-width: 600px) {
    

.mobile-nav {
  display: flex;
  align-items: center;
  margin-left: -10px;
  padding-top: 0px;
}

.navbar-icon {
  font-size: 30px;
  cursor: pointer;
  padding: 15px;
}

.bar {
  height: 3px;
  width: 25px;
  background-color: #0a0a0a;
  margin: 6px 0;
  transition: 0.4s;
}

.bar.open {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.nav-links {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 90px;
  right: 0;
  background-color: #383333;
  width: 100%;
  text-align: center;
  z-index: 1;
}

.nav-links a {
  padding: 15px;
  text-decoration: none;
  color: #f8f5f5;
  transition: 0.3s;
}

.nav-links a:hover {
  background-color: #f9f2f2;
  color: black;
}
  }